var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("layout-pay-box-section", {
        attrs: { "with-header": false },
        scopedSlots: _vm._u([
          {
            key: "main",
            fn: function () {
              return [
                _c("e-pay-box-sale-item-table", {
                  attrs: {
                    borderless: "",
                    "sticky-header": "",
                    "table-items": _vm.getItems,
                    "prices-to-right": "",
                    "min-height":
                      _vm.getHeight + 14 - _vm.deliveryAddressBoxInfoHeight,
                    "has-chopp-gift-card-conversion":
                      _vm.isChoppGiftCardConversion,
                  },
                  on: {
                    "show-edit-quantity": _vm.showEditQuantityCell,
                    "clean-row-update": _vm.cleanUpdate,
                    "update-item": _vm.updateItem,
                    "remove-item": _vm.removeItem,
                  },
                }),
                _vm.isDelivery
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-white w-100 d-flex align-items-center justify-content-start border-top-dark",
                        style:
                          "height: " + _vm.deliveryAddressBoxInfoHeight + "px",
                      },
                      [
                        false
                          ? _c(
                              "div",
                              { staticClass: "mx-1" },
                              [
                                _c("e-button-icon", {
                                  staticStyle: { padding: "5px" },
                                  attrs: { variant: "danger", icon: "XIcon" },
                                  on: { click: _vm.onCancelDelivery },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mx-1" },
                          [
                            _c("feather-icon", {
                              staticClass: "text-primary",
                              attrs: { icon: "TruckIcon", size: "30" },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                            _vm._v(" Endereço para entrega: "),
                            _c("br"),
                            _vm._v(" " + _vm._s(_vm.deliveryAddressText) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "b-row",
                  { staticClass: "h-100 d-flex align-items-center" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                        attrs: { md: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-baseline",
                            staticStyle: { gap: "10px" },
                          },
                          [
                            _c("div", { staticClass: "footer-value-info" }, [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "h5 text-right mb-0 text-dark",
                                  },
                                  [_vm._v(" Subtotal ")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-end",
                                  staticStyle: { gap: "10px" },
                                },
                                [
                                  !_vm.itemReadOnly
                                    ? _c("e-button-icon", {
                                        staticStyle: { padding: "5px" },
                                        attrs: {
                                          id: "paybox-item_list-btn_show_discount",
                                          pill: "",
                                          title: _vm.$t("Adicionar desconto"),
                                          variant: "dark",
                                          icon: "PlusIcon",
                                        },
                                        on: { click: _vm.onShowModalDiscount },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "h5 text-right mb-0 text-dark",
                                    },
                                    [_vm._v(" Descontos ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "footer-value-info" }, [
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getSubtotalSaleWithoutDiscountInfo,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getTotalItemsDiscountInfo,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm.isDelivery
                          ? _c("div", [
                              _c(
                                "p",
                                { staticClass: "h3 text-left mb-0 text-dark" },
                                [
                                  _vm._v(" Frete "),
                                  _c("br"),
                                  _c("span", { staticClass: "h3 text-dark" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.deliveryForm.deliveryTax,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.showComission
                          ? _c("div", [
                              _c("div", { staticClass: "footer-value-info" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    staticStyle: { gap: "10px" },
                                  },
                                  [
                                    !_vm.itemReadOnly &&
                                    _vm.hasWaiterComissionConfig
                                      ? _c("e-button-icon", {
                                          staticStyle: { padding: "5px" },
                                          attrs: {
                                            id: "paybox-item_list-btn_add_comission",
                                            pill: "",
                                            title: _vm.$t("Adicionar comissão"),
                                            variant: "dark",
                                            icon: "PlusIcon",
                                          },
                                          on: {
                                            click: _vm.onShowModalComission,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "h5 text-right mb-0 text-dark",
                                      },
                                      [_vm._v(" Comissão ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "footer-value-info" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "h3 text-left mb-0 text-dark",
                                  },
                                  [
                                    _c("span", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.comission || 0,
                                              null,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "h2 text-left mb-0 text-dark" },
                            [
                              _vm._v(" Total "),
                              _c("br"),
                              _c("span", { staticClass: "h1 text-dark" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.getTotalSale,
                                        null,
                                        2
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal-discount", {
        ref: "modalDiscount",
        attrs: { "initial-value": _vm.paymentForm.discount },
        on: { "click-confirm": _vm.onAddDiscount },
      }),
      _c("modal-comission", {
        ref: "modalComission",
        on: { "click-confirm": _vm.onAddComission },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }