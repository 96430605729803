<template>
  <section>
    <layout-pay-box-section :with-header="false">
      <template #main>
        <e-pay-box-sale-item-table
          borderless
          sticky-header
          :table-items="getItems"
          prices-to-right
          :min-height="getHeight + 14 - deliveryAddressBoxInfoHeight"
          :has-chopp-gift-card-conversion="isChoppGiftCardConversion"
          @show-edit-quantity="showEditQuantityCell"
          @clean-row-update="cleanUpdate"
          @update-item="updateItem"
          @remove-item="removeItem"
        />

        <div
          v-if="isDelivery"
          class="bg-white w-100 d-flex align-items-center justify-content-start border-top-dark"
          :style="`height: ${deliveryAddressBoxInfoHeight}px`"
        >
          <div
            v-if="false"
            class="mx-1"
          >
            <e-button-icon
              variant="danger"
              icon="XIcon"
              style="padding: 5px"
              @click="onCancelDelivery"
            />
          </div>
          <div class="mx-1">
            <feather-icon
              icon="TruckIcon"
              size="30"
              class="text-primary"
            />
          </div>
          <div>
            <p class="mb-0 font-weight-bold">
              Endereço para entrega: <br>
              {{ deliveryAddressText }}
            </p>
          </div>
        </div>
      </template>

      <template #footer>
        <b-row class="h-100 d-flex align-items-center">
          <b-col
            md="12"
            class="d-flex justify-content-between align-items-center"
          >
            <div
              class="d-flex align-items-baseline"
              style="gap: 10px"
            >
              <div class="footer-value-info">
                <div>
                  <p class="h5 text-right mb-0 text-dark">
                    Subtotal
                  </p>
                </div>
                <div
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <e-button-icon
                    v-if="!itemReadOnly"
                    id="paybox-item_list-btn_show_discount"
                    pill
                    :title="$t('Adicionar desconto')"
                    variant="dark"
                    style="padding: 5px"
                    :icon="'PlusIcon'"
                    @click="onShowModalDiscount"
                  />
                  <p class="h5 text-right mb-0 text-dark">
                    Descontos
                  </p>
                </div>
              </div>

              <div class="footer-value-info">
                <p class="h3 text-left mb-0 text-dark">
                  <span class="">
                    {{ getSubtotalSaleWithoutDiscountInfo | currency(null, 2) }}
                  </span>
                </p>
                <p class="h3 text-left mb-0 text-dark">
                  <span class=""> {{ getTotalItemsDiscountInfo | currency(null, 2) }} </span>
                </p>
              </div>
            </div>

            <div v-if="isDelivery">
              <p class="h3 text-left mb-0 text-dark">
                Frete <br>
                <span class="h3 text-dark">
                  {{ deliveryForm.deliveryTax | currency(null, 2) }}
                </span>
              </p>
            </div>

            <div v-if="showComission">
              <div class="footer-value-info">
                <div
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <e-button-icon
                    v-if="!itemReadOnly && hasWaiterComissionConfig"
                    id="paybox-item_list-btn_add_comission"
                    pill
                    :title="$t('Adicionar comissão')"
                    variant="dark"
                    style="padding: 5px"
                    :icon="'PlusIcon'"
                    @click="onShowModalComission"
                  />

                  <p class="h5 text-right mb-0 text-dark">
                    Comissão
                  </p>
                </div>
              </div>

              <div class="footer-value-info">
                <p class="h3 text-left mb-0 text-dark">
                  <span class=""> {{ (comission || 0) | currency(null, 2) }} </span>
                </p>
              </div>
            </div>

            <div>
              <p class="h2 text-left mb-0 text-dark">
                Total <br>
                <span class="h1 text-dark"> {{ getTotalSale | currency(null, 2) }} </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </template>
    </layout-pay-box-section>

    <modal-discount
      ref="modalDiscount"
      :initial-value="paymentForm.discount"
      @click-confirm="onAddDiscount"
    />

    <modal-comission
      ref="modalComission"
      @click-confirm="onAddComission"
    />
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formulateHelper, keyboardCode, address, payBoxTypes, authorizationRules } from '@/mixins'
import delegablePermissions from '@/utils/delegable-permissions'
import EButtonIcon from '@/views/components/EButtonIcon.vue'
import EPayBoxSaleItemTable from '@/views/components/tables/EPayBoxSaleItemTable.vue'
import LayoutPayBoxSection from './LayoutPayBoxSection.vue'
import ModalDiscount from './tabs/components/ModalDiscount.vue'
import ModalComission from './modals/ModalComission.vue'

const getInitialForm = () => ({
  amount: 1,
})

export default {
  components: {
    LayoutPayBoxSection,
    BRow,
    BCol,
    ModalDiscount,
    EButtonIcon,
    ModalComission,
    EPayBoxSaleItemTable,
  },

  mixins: [formulateHelper, keyboardCode, payBoxTypes, authorizationRules],

  props: {
    getHeight: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formUpdate: getInitialForm(),
      delegateUserData: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv/payBox', [
      'items',
      'paymentStarted',
      'paymentForm',
      'isDelivery',
      'comission',
      'comissionFee',
      'deliveryForm',
      'tempDelegations',
    ]),

    ...mapGetters('pages/pdv/payBoxConfiguration', ['barConfig']),
    ...mapGetters('pages/pdv/payBox', [
      'getTotalSale',
      'getSubtotalSaleWithoutDiscountInfo',
      'getTotalItemsDiscountInfo',
      'getItems',
      'isConsignedSaleFinish',
      'isBarConsumption',
      'isChoppGiftCardConversion',
      'isOrder',
    ]),

    getComissionRadioOptions() {
      const defaultResult = [{ value: null, label: this.$t('Nenhum') }]
      const result =
        this.barConfig?.storeComissions?.map(c => ({
          value: c.comissionFee,
          label: this.$options.filters.percentage(c.comissionFee),
        })) || []

      return [...result, ...defaultResult]
    },

    hasWaiterComissionConfig() {
      return this.barConfig?.hasWaiterComission
    },

    showComission() {
      return this.isBarConsumption && (this.hasWaiterComissionConfig || this.comission > 0)
    },

    deliveryAddressText() {
      const ad = this.deliveryForm.address
      return ad
        ? address.methods.formatAddress(ad)
        : this.deliveryForm.observation.replace('Endereço de Entrega: ', '')
    },

    deliveryAddressBoxInfoHeight() {
      return this.isDelivery ? 70 : 0
    },

    itemReadOnly() {
      return this.isConsignedSaleFinish
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stRemoveItem: 'removeItem',
      stUpdateItem: 'updateItem',
      stSetDiscountPaymentForm: 'setDiscountPaymentForm',
      stSetDeliveryData: 'setDeliveryData',
      stSetAndCalculateComission: 'setAndCalculateComission',
      stValidatePayBoxActionIsBlocked: 'validatePayBoxActionIsBlocked',
      stAddTempDelegation: 'AddTempDelegation',
    }),

    async showEditQuantityCell(showAction, item) {
      if (item.palmBeerCardNumber && item.product.isCredit) {
        this.showWarning({ message: this.$t('Não é possível alterar a quantidade de produtos de crédito.') })
        return
      }

      if (this.isOrder && item.id) {
        this.showWarning({ message: this.$t('Para alterar quantidade ou excluir produtos de um pedido existente, utilizar a tela "Vendas > Pedidos", do ERP.') })
        return
      }

      if (this.isChoppGiftCardConversion || item.choppGiftCardNumber) {
        return
      }

      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PRODUCT_UPDATE,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (this.itemReadOnly || item.palmBeerCardNumber) {
        this.showWarning({ message: this.$t('Não é possível alterar a quantidade') })
        return
      }

      if (item.paymentStarted || this.isBarConsumption) {
        try {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_PRODUCT_EDIT
          )
          showAction()
        } catch (error) {
          console.error(error)
          if (error.message) {
            this.showWarning({ message: error.message })
          }
        }
      }
      else {
        showAction()
      }
    },
    cleanUpdate() {
      this.delegateUserData = null
    },

    async updateItem(item, formUpdate, cleanUpdateCallback) {
      try {
        const qttyChanged = (formUpdate.quantity || 0) - (item?.quantity || 0)
        const { delegateUserData, productStock, kitItemName } = await this.authAddProductWithNegativeStock(
          item?.skuProduct,
          qttyChanged,
          this.getItems,
          this.tempDelegations
        )
        this.delegateUserData = delegateUserData

        if (productStock < 0 && !this.delegateUserData) {
          let message = this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.UPDATE', { name: item?.skuProduct?.name })
          if (kitItemName) message += this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.KIT_ITEM', { name: kitItemName })

          const confirmAddWithNegativeStock = await this.confirm({
            title: this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.TITLE'),
            text: message,
          })
          if (!confirmAddWithNegativeStock) {
            cleanUpdateCallback()
            return
          }
        }
        if (this.delegateUserData) {
          this.stAddTempDelegation(this.delegateUserData || {})
        }
      } catch (error) {
        if (error.message) {
          this.showWarning({ message: error.message })
        }
        cleanUpdateCallback()
        return
      }

      this.stUpdateItem({ payload: { ...item, quantity: formUpdate.quantity } })
      cleanUpdateCallback()
    },

    async removeItem(saleItem) {
      const remove = async () => {
        await this.stRemoveItem(saleItem)
        this.showSuccess({ message: this.$t('Produto removido') })
      }

      if (this.isOrder && saleItem.id) {
        this.showWarning({ message: this.$t('Para alterar quantidade ou excluir produtos de um pedido existente, utilizar a tela "Vendas > Pedidos", do ERP.') })
        return
      }
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PRODUCT_REMOVE,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (saleItem.paymentStarted || this.isBarConsumption || saleItem.localId) {
        try {
          const confirm = await this.confirm({
            text: `Remover o produto: ${saleItem.skuProduct.name}?`,
            focusCancel: true,
          })
          if (!confirm) return
          // eslint-disable-next-line no-unused-vars
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_PRODUCT_DELETE
          )

          await remove()
        } catch (error) {
          console.error(error)
          if (error.message) {
            this.showWarning({ message: error.message })
          }
        }
      }
      else {
        const confirm = await this.confirm({
          text: `Remover o produto: ${saleItem.skuProduct.name}?`,
          focusCancel: true,
        })
        if (confirm) await remove()
      }
    },

    async onShowModalDiscount() {
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.SALE_ADD_DISCOUNT,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      if (this.paymentForm.balance <= 0) {
        this.showWarning({ message: this.$t('Não há saldo na venda') })
        return
      }
      this.$refs.modalDiscount.showModal()
    },
    onAddDiscount(form) {
      this.stSetDiscountPaymentForm(form.discount)
    },

    async onShowModalComission() {
      this.$refs.modalComission.showModal({ comissionFee: this.comissionFee })
    },
    onAddComission(comissionForm) {
      this.stSetAndCalculateComission({ comissionFee: comissionForm?.comissionFee })
    },

    async onCancelDelivery() {
      const confirm = await this.confirm({ title: this.$t('Confirme o cancelamento da entrega') })
      if (confirm) this.stSetDeliveryData({ isDelivery: false })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-value-info {
  p {
    line-height: 25px;
  }
}
</style>
