var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "tab-container" },
    [
      _c(
        "FormulateForm",
        {
          ref: "formProductTab",
          staticStyle: { height: "100%" },
          attrs: { name: "formProductTab" },
        },
        [
          _c(
            "b-card-actions",
            { attrs: { "no-actions": "" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.isKioskStore
                            ? _vm.$t("Pesq.")
                            : _vm.$t("Pesquisa"),
                          icon: "search",
                          size: "sm",
                          "text-shortcuts": ["F4"],
                          disabled: _vm.isChoppGiftCardConversion,
                        },
                        on: { click: _vm.showSearchAdvanced },
                      }),
                      _vm.isKioskStore
                        ? _c("e-button", {
                            staticClass: "ml-1",
                            attrs: {
                              text: _vm.$t("Chope"),
                              icon: "credit-card",
                              size: "sm",
                              "text-shortcuts": ["F9"],
                              busy: _vm.choppCardLoading,
                              disabled: _vm.isChoppGiftCardConversion,
                            },
                            on: { click: _vm.readChoppCard },
                          })
                        : _vm._e(),
                      _vm.isKioskStore
                        ? _c("e-button", {
                            staticClass: "ml-1",
                            attrs: {
                              text: _vm.$t("Saldo"),
                              icon: "currency-dollar",
                              size: "sm",
                              "text-shortcuts": ["F8"],
                              busy: _vm.choppCardLoading,
                              disabled: _vm.isChoppGiftCardConversion,
                            },
                            on: { click: _vm.readChoppCardBalance },
                          })
                        : _vm._e(),
                      _vm.isKioskStore
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "m-1",
                              staticStyle: { height: "35px" },
                              attrs: {
                                id: "dropdown-offset",
                                offset: "25",
                                size: "sm",
                                disabled: _vm.isChoppGiftCardConversion,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "25px",
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "gift" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("Pres.")) + " "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                514856221
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.modalChoppGiftCard.showModal(
                                        "Sell"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Vender Cartão")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    href: "#",
                                    disabled: _vm.saleInProgress,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.modalChoppGiftCard.showModal(
                                        "Convert"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Converter Cartão")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "productInputAmount",
                          name: "amount",
                          type: "number",
                          label: _vm.$t("Qtd."),
                          validation: "min:1",
                          min: "1",
                          disabled: _vm.isChoppGiftCardConversion,
                        },
                        on: { focus: _vm.onFocusAmount },
                        model: {
                          value: _vm.itemForm.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemForm, "quantity", _vm._n($$v))
                          },
                          expression: "itemForm.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "10" } },
                    [
                      _c(
                        "FormulateForm",
                        {
                          ref: "formSearchProduct",
                          attrs: { name: "formSearchProduct" },
                          on: { submit: _vm.searchItem },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center",
                              staticStyle: { gap: "10px" },
                            },
                            [
                              _c("FormulateInput", {
                                staticClass: "w-100",
                                attrs: {
                                  id: "productInputBarcode",
                                  name: "barcode",
                                  label: _vm.$t("Código de barras"),
                                  "data-enable-scan": "",
                                  type: "text",
                                  disabled: _vm.isChoppGiftCardConversion,
                                },
                                on: { click: _vm.onFocusBarcode },
                                model: {
                                  value: _vm.barcode,
                                  callback: function ($$v) {
                                    _vm.barcode = $$v
                                  },
                                  expression: "barcode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.getItemInfo.name || "-",
                "no-actions": "",
                "show-loading": _vm.searchLoading,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex", staticStyle: { gap: "10px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "product-img" },
                        [
                          _c("b-img", {
                            style: {
                              height: "150px",
                              width: "150px",
                              objectFit: "contain",
                            },
                            attrs: {
                              thumbnail: "",
                              src: _vm.getItemInfo.imageSquare || _vm.noImage,
                              alt:
                                "Imagem " +
                                (_vm.getItemInfo.name || "sem imagem"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 d-flex flex-column justify-content-between",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-start",
                              staticStyle: { gap: "10px" },
                            },
                            [
                              _c("p", { staticClass: "m-0" }),
                              !!_vm.getItemInfo.name
                                ? _c(
                                    "div",
                                    [
                                      _c("feather-icon", {
                                        staticClass: "text-dark",
                                        attrs: {
                                          id: "stockIcon",
                                          icon: "PackageIcon",
                                          size: "20",
                                        },
                                      }),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "stockIcon",
                                            triggers: "hover",
                                            placement: "right",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "h5 text-white mb-auto",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("Estoque atual")
                                                  ) +
                                                  ": "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "h4 text-white",
                                                  class: {
                                                    "text-danger":
                                                      _vm.getItemInfo.stock < 0,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getItemInfo.stock
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.getItemInfo.hasPromotion
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "h5 text-white mb-auto",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("Em Promoção")
                                                      ) +
                                                      ": "
                                                  ),
                                                  _vm.getItemInfo
                                                    .promotionQuantityUnlimited
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "h4 text-white",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Ilimitado"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "h4 text-white",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getItemInfo
                                                                  .promotionQuantityAvailable
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-end",
                            },
                            [
                              _c("div", [
                                _c("p", { staticClass: "m-0" }, [
                                  _c("span", { staticClass: "h3" }, [
                                    _vm._v("Valor unitário"),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm.getItemInfo.hasDiscount
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "h4 text-danger",
                                          staticStyle: {
                                            "text-decoration": "line-through",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.getItemInfo.price
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("p", { staticClass: "h2 m-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getItemInfo.hasDiscount
                                            ? _vm.getItemInfo.priceWithDiscount
                                            : _vm.getItemInfo.price
                                        )
                                      ) +
                                      " x" +
                                      _vm._s(_vm.getItemInfo.productAmount) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c("p", { staticClass: "h3 mb-0 text-right" }, [
                                  _vm._v(" Subtotal "),
                                ]),
                                _c("p", { staticClass: "h2 mb-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getItemInfo.subtotal
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            { attrs: { "no-actions": "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "product_tab-observation",
                          type: "textarea",
                          name: "observation",
                          rows: 4,
                          label: _vm.$t("Observação"),
                        },
                        model: {
                          value: _vm.itemForm.observation,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemForm, "observation", $$v)
                          },
                          expression: "itemForm.observation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-advanced-search", {
        ref: "modalAdvSearch",
        on: { "add-product": _vm.onAddItem },
      }),
      _c("modal-credit-products", {
        ref: "modalCreditProducts",
        on: { "add-product": _vm.onAddItem, cancel: _vm.creditProductCanceled },
      }),
      _c("modal-credit-balance", { ref: "modalCreditBalance" }),
      _c("modal-chopp-gift-card", {
        ref: "modalChoppGiftCard",
        on: { "click-confirm": _vm.onChoppGiftCardInformed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }